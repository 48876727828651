import request from "@/utils/request";

//选择订单列表
export function queryTaskAndCount(data = {}) {
  return request({ url: "/sys/batch/TaskList", method: "post", data });
}

//上传保存
export function batchAdd(data = {}) {
  return request({
    url: "/sys/batch/add",
    method: "post",
    data,
  });
}

//批次列表查询
export function queryAllBatch(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/batch/queryAllBatch?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}
//详细信息
export function GetBatchTask(data) {
  return request({
    url: "/sys/batch/batchTask/" + data.id,
    method: "post",
    data,
  });
}

//查看学生明细
export function queryInfoByTask(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/batch/queryInfoByTask?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}


//上传信息确认列表
export function getContacts(data = {}) {
  return request({
    url: "/sys/sysParam/getContacts",
    method: "get",
    data,
  });
}

//批次打包下载
export function getDownloadBatch(id) {
  return request({
    url: "/sys/batch/downloadBatch?batchId=" + id,
    method: "get",
    id,
  });
}

//查看上传信息
export function GetTaskConfirm(data) {
  return request({
    url: "/sys/batch/taskConfirm?id=" + data.id+"&collectType="+data.collectType,
    method: "get",
    data,
  });
}

//学校信息
export function schoolCollectsInformation(data = {}) {
  return request({
    url: "/sys/schoolCollectsInformation/info?taskId="+data.id+"&collectType="+data.collectType,
    method: "get",
    data,
  });
}
