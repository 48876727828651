<template>
  <div class="box">
    <!-- 搜索部分 -->
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">批次名称</label>
        <el-input
          v-model="searchName"
          placeholder="请输入批次名称"
          autocomplete="off"
          size="small"
          clearable
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集时间</label>
        <el-date-picker
          v-model="searchCreateDate"
          type="date"
          placeholder="选择开始时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          v-model="searchCreateDateEnd"
          type="date"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>

      <div class="condition-item">
        <el-button type="primary" size="small" @click="searchList">搜索</el-button>
      </div>
    </div>
    <!-- 列表盒子部分 -->
    <div style="display: flex; flex-direction: column; height: calc(100vh - 280px)">
      <el-table
        border
        v-loading="DownloadLoading"
        element-loading-text="文件正在打包中，请稍后..."
        ref="multipleTable"
        :data="allList"
        style="width: 100%; text-align: center"
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <el-table-column prop="batchName" label="批次名称" min-width="300">
        </el-table-column>

        <el-table-column align="center" prop="createTime" label="创建时间" width="180">
          <template slot-scope="scope">
            {{ formDateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="peopleNumber" label="上传人数" width="120">
        </el-table-column>

        <el-table-column align="center" label="批次明细" min-width="120">
          <template slot-scope="scope">
            <el-button type="text" @click="viewbatch(scope.row.id)">详细信息 </el-button>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="batchType" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="downloadButton"
              @click="handleDownload(scope.row.id)"
            >
              打包下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div style="display: flex; justify-content: right; padding-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        style="display: flex; justify-content: center; flex-direction: row"
        :page-sizes="[100, 200, 300, 400, 500]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
      <!-- 查看详细信息弹窗 -->
        <el-dialog
          title="详细信息"
          :visible.sync="dialogVisibleview"
          width="80%"
          class="ERER"
        >
          <!-- 搜索部分 -->
          <div style="display: flex; flex-wrap: wrap">
            <div class="condition-item">
              <label class="fn-14">任务名称</label>
              <el-input
                clearable
                v-model="viewsearch.searchTaskName"
                placeholder="请输入任务名称"
                autocomplete="off"
                size="small"
              ></el-input>
            </div>
            <div class="condition-item">
              <label class="fn-14">采集类型</label>
              <el-select
                size="small"
                clearable
                v-model="viewsearch.searchState"
                placeholder="全部"
                style="width: 180px"
              >
                <el-option
                  v-for="item in StateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div class="condition-item">
              <el-button
                type="primary"
                size="small"
                style="width: 85px"
                @click="handleSearchview"
                >查询</el-button
              >
            </div>
          </div>

          <!-- 弹窗列表盒子 -->
          <div>
            <el-table
              border
              :data="viewList"
              style="width: 100%; text-align: center"
              height="400"
              :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
            >
              <template #empty>
                <p>
                  {{ tableViewLoading == true ? "数据加载中" : "暂无数据" }}
                </p>
              </template>

              <el-table-column prop="title" label="任务名称" width="260" fixed>
              </el-table-column>
              <el-table-column align="center" prop="collectType" label="类型" width="120">
                <template slot-scope="scope">
                  <span v-show="scope.row.collectType == 0">校拍</span>
                  <span v-show="scope.row.collectType == 1">散拍</span>
                  <span v-if="scope.row.collectType == 2">
                    <span v-show="scope.row.realCollectType == 0">校拍</span>
                    <span v-show="scope.row.realCollectType == 1">散拍</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="schoolCode"
                label="院校代码"
                width="120"
              >
              </el-table-column>
              <el-table-column align="center" prop="contact" label="联系人" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row.contact }}</span></template
                >
              </el-table-column>
              <el-table-column
                align="center"
                prop="contactPhone"
                label="联系电话"
                width="160"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.contactPhone }}</span></template
                >
              </el-table-column>
              <el-table-column
                prop="endDate"
                :formatter="columnDateFormat"
                label="采集时间"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="batchTime"
                :formatter="columnDateFormat"
                label="上传时间"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="photographer"
                label="摄影师"
                width="120"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="contactAddress"
                label="邮寄地址"
                width="260"
              >
              </el-table-column>
              <el-table-column
                align="center"
                width="120"
                prop="totalCount"
                label="采集人数"
              >
              </el-table-column>
              <el-table-column align="center" width="120" prop="postalCode" label="邮编">
              </el-table-column>
              <el-table-column
                align="center"
                width="120"
                prop="mailNumber"
                label="邮寄数"
              >
              </el-table-column>
              <el-table-column
                align="center"
                width="150"
                prop="mailGroup"
                label="邮寄分组情况"
              >
                <template slot-scope="scope">
                  <span v-html="scope.row.mailGroup"></span>
                </template>
              </el-table-column>
              <el-table-column prop="schoolName" label="操作" align="center" width="280">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleInfo(scope.row)">
                    查看明细
                  </el-button>
                  <el-button type="text" @click="handleupload(scope.row)">
                    查看上传信息
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-dialog>
      <!-- 查看明细弹窗 -->
        <el-dialog
          title="查看明细"
          :visible.sync="InfoVisibleview"
          width="80%"
          class="ERER"
        >
          <!-- 搜索部分 -->
          <div style="display: flex; flex-wrap: wrap">
            <div class="condition-item">
              <label class="fn-14">拍照序号</label>
              <el-input
                style="width: 120px"
                placeholder="请输入拍照序号"
                autocomplete="off"
                size="small"
                v-model="infosearch.searchIndexNumber"
                clearable
              ></el-input>
            </div>
            <div class="condition-item">
              <label class="fn-14">姓名</label>
              <el-input
                style="width: 120px"
                placeholder="请输入姓名"
                autocomplete="off"
                size="small"
                clearable
                v-model="infosearch.searchContact"
              ></el-input>
            </div>
            <div class="condition-item">
              <label class="fn-14">身份证号</label>
              <el-input
                style="width: 180px"
                placeholder="请输入身份证号"
                autocomplete="off"
                size="small"
                clearable
                v-model="infosearch.searchIdNumber"
              ></el-input>
            </div>
            <div class="condition-item">
              <el-button
                type="primary"
                size="small"
                style="width: 85px"
                @click="handleSearchInfo"
                >查询</el-button
              >
            </div>
          </div>

          <!-- 弹窗列表盒子 -->
          <div>
            <el-table
              border
              :data="InfoList"
              style="width: 100%; text-align: center"
              height="400"
              :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
              v-loading="tableInfoLoading"
            >
              <template #empty>
                <p>
                  {{ tableInfoLoading == true ? "数据加载中" : "暂无数据" }}
                </p>
              </template>

              <el-table-column prop="batchIndex" label="拍照序号" width="120" fixed>
              </el-table-column>
              <el-table-column align="center" prop="name" label="姓名" width="120">
                <template slot-scope="scope">
                  <span @click="PhotoView(scope.row)" style="cursor: pointer">
                    <i class="el-icon-picture"></i
                    >{{ execDecrypt(scope.row.name, "name") }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="idNumber"
                label="身份证号"
                width="240"
              >
                <template slot-scope="scope">
                  <span>{{ execDecrypt(scope.row.idNumber, "Idcard") }}</span></template
                >
              </el-table-column>
              <el-table-column align="center" prop="studentCode" label="学号" width="160">
              </el-table-column>
              <el-table-column
                align="center"
                prop="schoolType"
                label="学校类型"
                width="160"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="educationType"
                label="学历类型"
                width="160"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="schoolCode"
                label="学校代码"
                width="160"
              >
              </el-table-column>
              <el-table-column align="center" prop="schoolName" label="学校" width="180">
              </el-table-column>
              <el-table-column align="center" prop="major" label="专业" width="180">
              </el-table-column>
              <el-table-column align="center" prop="cjm" label="采集码" width="180">
              </el-table-column>
            </el-table>
          </div>
          <div style="display: flex; justify-content: center">
            <el-pagination
              @size-change="handleSizeChangeInfo"
              @current-change="handleCurrentChangeInfo"
              :current-page="currentPageInfo"
              style="display: flex; justify-content: center; flex-direction: row"
              :page-sizes="[10, 20, 30, 40, 50, 100]"
              :page-size="pageSizeInfo"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalInfo"
            >
            </el-pagination>
          </div>
        </el-dialog>
      <!-- 查看照片 -->
      <el-dialog
        title="学生照片"
        :visible.sync="PhotoVisibleview"
        width="50%"
        class="ERER"
      >
        <div style="text-align: center">
          <el-image :src="photoImg"></el-image>
        </div>
      </el-dialog>
      <!-- 上传信息弹窗 -->
        <el-dialog
          title="
      查看上传信息 
      "
          :visible.sync="informationVisible"
          width="900px"
        >
          <el-form ref="iSplitData" :model="iSplitData" label-width="120px">
            <el-form-item
              label="学校名称"
              prop="schoolName"
              v-show="iSplitData.schoolName"
            >
              <el-input
                disabled
                v-model="iSplitData.schoolName"
                type="text"
                placeholder="请输入学校名称"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="学校代码"
              prop="schoolCode"
              v-show="iSplitData.schoolCode"
            >
              <el-input
                disabled
                type="text"
                v-model="iSplitData.schoolCode"
                placeholder="请输入学校编码"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否需要分装" v-show="iSplitData.isSplit">
              <el-radio-group v-model="iSplitData.isSplit" disabled>
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="联系人"
              prop="contactName"
              v-show="iSplitData.contactName"
            >
              <el-input
                disabled
                type="text"
                v-model="iSplitData.contactName"
                placeholder="请输入联系人"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="联系电话"
              prop="contactPhone"
              v-show="iSplitData.contactPhone"
            >
              <el-input
                disabled
                type="text"
                v-model="iSplitData.contactPhone"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="邮政编码"
              prop="postalCode"
              v-show="iSplitData.postalCode"
            >
              <el-input
                disabled
                type="text"
                v-model="iSplitData.postalCode"
                placeholder="请输入邮政编码"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="邮寄地址"
              prop="contactAddress"
              v-show="iSplitData.contactAddress"
            >
              <el-input
                disabled
                type="text"
                v-model="iSplitData.contactAddress"
                placeholder="请输入邮寄地址"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="电子邮箱"
              prop="contactEmail"
              v-show="iSplitData.contactEmail"
            >
              <el-input
                disabled
                type="text"
                v-model="iSplitData.contactEmail"
                placeholder="请输入电子邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="摄影师姓名"
              prop="photographer"
              v-show="iSplitData.photographer"
            >
              <el-input
                disabled
                type="text"
                v-model="iSplitData.photographer"
                placeholder="请输入摄影师姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="拍摄时间" prop="shootTime" v-show="iSplitData.shootTime">
              <el-input
                disabled
                type="text"
                :value="formDateFormat(iSplitData.shootTime)"
                placeholder="请输入拍摄时间"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="拍摄人数"
              prop="shootCount"
              v-show="iSplitData.shootCount"
            >
              <el-input
                disabled
                type="text"
                v-model="iSplitData.shootCount"
                placeholder="请输入拍摄人数"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="分社名称"
              prop="centerName"
              v-show="iSplitData.centerName"
            >
              <el-input
                disabled
                type="text"
                v-model="iSplitData.centerName"
                placeholder="请输入分社名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="拍摄卡号" prop="centerNo" v-show="iSplitData.centerNo">
              <el-input
                disabled
                type="text"
                v-model="iSplitData.centerNo"
                placeholder="请输入卡号"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否加急" prop="isWorry" v-show="iSplitData.isWorry">
              <el-radio-group v-model="iSplitData.isWorry" disabled>
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上传备注" prop="remarks" v-show="iSplitData.remarks">
              <el-input
                disabled
                v-model="iSplitData.remarks"
                type="textarea"
                placeholder="请填写其它上传需求"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-dialog>
     
  </div>
</template>
<script>
import { dateFormat } from "@/utils/date";
import {
  queryAllBatch,
  GetBatchTask,
  getDownloadBatch,
  queryInfoByTask,
  GetTaskConfirm,
} from "@/api/batch";
import { exportprocess } from "@/api/student";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import { queryPageButton } from "@/api/permission";

export default {
  name: "batchList",
  data() {
    return {
      // 列表搜索
      searchName: "",
      searchCreateDate: null,
      searchCreateDateEnd: null,
      viewbatchId: null,
      //详细搜索
      viewsearch: {
        searchTaskName: "",
        searchState: null,
      },
      // 明细搜索
      infosearch: {
        searchContact: "",
        searchIdNumber: null,
        searchIndexNumber: null,
      },
      InfoBatchId: null,
      InfoCollectType: null,
      dialogVisibleview: false,
      tableViewLoading: false,
      tableInfoLoading: false,
      DownloadLoading: false,
      currentPage: 1,
      pageSize: 100,
      total: 0,
      currentPageInfo: 1,
      pageSizeInfo: 20,
      totalInfo: 0,
      allList: [],
      viewList: [],
      InfoList: [],
      loading: false,
      InfoVisibleview: false,
      StateList: [
        {
          label: "校拍",
          value: 0,
        },
        {
          label: "散拍",
          value: 1,
        },
      ],
      PhotoVisibleview: false,
      photoImg: null,
      informationVisible: false,
      iSplitData: {},
      //权限按钮
      downloadButton: false,
    };
  },
  mounted() {
    this.queryLIst();
    this.queryButton();
  },

  methods: {
    queryButton() {
      const data = 120;
      queryPageButton(data).then((resp) => {
        // console.log(resp);
        for (const button of resp.data) {
          if (button.code === "downloadButton") {
            this.downloadButton = true;
          }
        }
      });
    },
    queryLIst() {
      const postData = {};
      postData.batchName = this.searchName;
      postData.beginTime = this.formDateFormat(this.searchCreateDate);
      postData.endTime = this.formDateFormat(this.searchCreateDateEnd);
      // }
      // 全部批次列表
      queryAllBatch(postData, this.currentPage, this.pageSize).then((resp) => {
        if (resp.code == 0) {
          let datas = resp.data.content;
          this.allList = datas;
          this.total = resp.data.totalElements;
        }
      });
    },
    // 查看照片
    PhotoView(data) {
      // pathCollectedPhoto
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" +
        data.pathCollectedPhoto +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            this.PhotoVisibleview = true;
            const imgContent = response.data;
            this.photoImg = window.URL.createObjectURL(imgContent);
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },
    // 查看上传
    handleupload(data) {
      this.informationVisible = true;
      let postData = {
        id: data.id,
        collectType: data.collectType,
      };
      GetTaskConfirm(postData).then((res) => {
        if (res.code == 0) {
          this.iSplitData = res.data;
        }
      });
    },
    // 查看明细
    handleInfo(data) {
      this.InfoVisibleview = true;
      this.InfoBatchId = data.id;
      this.InfoCollectType = data.collectType;
      this.InfoList = [];
      this.infosearch = {};
      let postData = {
        id: data.id,
        title: data.title,
        collectType: data.collectType,
      };
      this.InfobatchlList(postData);
    },
    InfobatchlList(data) {
      let postData = {
        id: data.id,
        title: data.title,
        collectType: data.collectType,
        batchIndex: this.infosearch.searchIndexNumber,
        name: this.infosearch.searchContact,
        idNumber: this.infosearch.searchIdNumber,
      };
      this.tableInfoLoading = true;
      queryInfoByTask(postData, this.currentPageInfo, this.pageSizeInfo).then((resp) => {
        if (resp.code == 0) {
          let dataview = resp.data.content;
          this.InfoList = dataview;
          this.totalInfo = resp.data.totalElements;
        }
        this.tableInfoLoading = false;
      });
    },
    // 查看详细信息
    viewbatch(id) {
      this.viewbatchId = id;
      this.dialogVisibleview = true;
      this.viewList = [];
      this.viewsearch = {};
      this.viewbatchlList(id);
    },
    viewbatchlList(id) {
      let postData = {
        id: id,
        title: this.viewsearch.searchTaskName,
        collectType: this.viewsearch.searchState,
      };
      this.tableViewLoading = true;
      GetBatchTask(postData).then((resp) => {
        if (resp.code == 0) {
          let dataview = resp.data;
          this.viewList = dataview;
        }
        this.tableViewLoading = false;
      });
    },
    // 全部批次列表搜索
    searchList() {
      this.queryLIst();
    },

    // 全部批次列表分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryLIst();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryLIst();
    },

    // 查看详情列表分页
    handleSearchview() {
      this.viewbatchlList(this.viewbatchId);
    },
    // 查看明细列表分页
    handleSearchInfo() {
      let postData = {
        id: this.InfoBatchId,
        collectType: this.InfoCollectType,
      };
      this.currentPageInfo = 1;
      this.InfobatchlList(postData);
    },
    handleSizeChangeInfo(val) {
      let postData = {
        id: this.InfoBatchId,
        collectType: this.InfoCollectType,
      };
      this.pageSizeInfo = val;
      this.InfobatchlList(postData);
    },
    handleCurrentChangeInfo(val) {
      let postData = {
        id: this.InfoBatchId,
        collectType: this.InfoCollectType,
      };
      this.currentPageInfo = val;
      this.InfobatchlList(postData);
    },
    /**
     * 时间格式化
     *
     * **/
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    // 打包下载按钮
    handleDownload(id) {
      const batchId = id;
      getDownloadBatch(batchId).then((resp) => {
        this.DownloadLoading = true;
        if (resp.code == "0") {
          this.taskExportprocess(resp.data.taskNo);
        } else {
          this.$notify({
            title: "警告",
            message: res.message,
            type: "warning",
          });
          this.DownloadLoading = false;
        }
      });
    },

    /**
     * 查询任务进度
     */
    taskExportprocess(taskNo = "") {
      let that = this;
      exportprocess(taskNo).then((res) => {
        if (res.data.state == 0) {
          setTimeout(() => {
            that.taskExportprocess(taskNo);
          }, 500);
        } else if (res.data.state == 1) {
          that.$notify({
            title: "成功",
            message: "打包下载成功",
            type: "success",
          });
          let urlFile = res.data.url;
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = urlFile;
          let fileName = Date.parse(new Date()) + ".rar";
          link.download = fileName;
          // console.log(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          this.DownloadLoading = false;
          this.queryLIst();
        } else {
          this.$notify({
            title: "警告",
            message: res.data.msg[0],
            type: "warning",
          });
          this.DownloadLoading = false;
          this.queryLIst();
        }
      });
    },

    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateTimeBegin(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd 00:00:00", new Date(dateValue));
      }
    },
    formDateTimeEnd(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd 23:59:59", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style scoped>
.el-checkbox__inner {
  border-radius: 20px !important;
}

/* 列表盒子 */
.plan-box {
  margin: 10px;
}

.form-bottom {
  margin-bottom: 0;
}
</style>
